'use client';

import { useEffect, useState } from 'react';

import { Input } from 'components/ui/input';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { XIcon } from 'lucide-react';
import { useDebouncedValue } from '@mantine/hooks';
import { useRouter } from 'next/navigation';
import { useSearchParams } from 'next/navigation';

export default function Search() {
  const searchParams = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('query') ?? '');

  const query = searchParams.get('query') ?? '';

  const router = useRouter();

  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

  useEffect(() => {
    const newParams = new URLSearchParams(window.location.search);
    if (debouncedSearchTerm) {
      newParams.set('query', debouncedSearchTerm);
    } else {
      newParams.delete('query');
    }
    router.replace(`?${newParams.toString()}`, { scroll: false });
  }, [debouncedSearchTerm, router]);

  useEffect(() => {
    setSearchTerm(query);
  }, [query]);

  return (
    <Input
      type="text"
      placeholder="Search"
      name="q"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      autoComplete="off"
      left={<MagnifyingGlassIcon className="size-5" />}
      right={
        searchTerm.length > 0 && (
          <XIcon
            className="size-5 animate-jump-in cursor-pointer"
            onClick={() => setSearchTerm('')}
          />
        )
      }
    />
  );
}
