'use client';

import { ToggleGroup, ToggleGroupItem } from 'components/ui/toggle-group';
import { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import type { BoxCategory } from '../battles/types';
import Link from 'next/link';
import { useCategory } from '../category-context';

export default function CategoryToggleGroup() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { category, setCategory } = useCategory();

  const [toggleGroupValue, setToggleGroupValue] = useState(
    pathname.split('/').slice(-1)[0],
  );

  useEffect(() => {
    if (pathname.split('/').slice(-1)[0] !== category) {
      setCategory(pathname.split('/').slice(-1)[0] as BoxCategory);
    }
  }, [pathname, category, setCategory]);

  return (
    <ToggleGroup
      type="single"
      value={toggleGroupValue}
      onValueChange={setToggleGroupValue}
    >
      <ToggleGroupItem value="magic" asChild>
        <Link
          href={`magic${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}
        >
          Magic: The Gathering
        </Link>
      </ToggleGroupItem>
      <ToggleGroupItem value="pokemon" asChild>
        <Link
          href={`pokemon${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}
        >
          Pokémon
        </Link>
      </ToggleGroupItem>
    </ToggleGroup>
  );
}
